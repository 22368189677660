import React from "react"
import { graphql } from "gatsby"
import * as styles from "../../styles/page/second.module.scss"
import * as topStyles from "../../styles/page/top.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"

// markup
const IndexPage = ({data}) => {
  const markdownRemark = data.markdownRemark
  const { frontmatter } = markdownRemark
  const { title, description, slug } = frontmatter

  return (
    <Layout>
      <Meta 
        title={title}
        description={description}
        path={slug}
      />

      <BreadCrumb
        currentTitle={title}
      />

      <div className={styles.pageTitle}>
        <h1 className={`${styles.pageTitleHeading} mb-6`}>{title}</h1>
      </div>

      <main className={styles.pageContents}>
        <div className={topStyles.contentsBanner}>
          <div className={topStyles.contentsBannerWrap}>
            <a href="https://www.youtube.com/watch?v=fXDPwbQrNiA&list=PLZH3AKTCrVsXEcdJJep6fl4Mb2Wy4eHL5" className={topStyles.contentsBannerCard} target="_blank" rel="noopener noreferrer">
              <img src="/images/top/interview-banner-vol3.png" alt="" />
              <div className={topStyles.contentsBannerCardMoveiButton}>
                <img src="/images/top/movie-button.png" alt="" />
              </div>
              <h2 className={topStyles.contentsBannerCardSmallTitle}>
              経営者インタビュー ～バイオものづくり～
              </h2>
            </a>
            <a href="https://www.youtube.com/watch?v=Y7pX9oTv8KU&list=PLZH3AKTCrVsXEcdJJep6fl4Mb2Wy4eHL5" className={topStyles.contentsBannerCard} target="_blank" rel="noopener noreferrer">
              <img src="/images/top/interview-banner-vol2.png" alt="" />
              <div className={topStyles.contentsBannerCardMoveiButton}>
                <img src="/images/top/movie-button.png" alt="" />
              </div>
              <h2 className={topStyles.contentsBannerCardSmallTitle}>
              経営者インタビュー 〜カーボンリサイクル〜
              </h2>
            </a>
          </div>
        </div>
        <div className={topStyles.contentsBanner}>
          <div className={topStyles.contentsBannerWrap}>
            <a href="https://www.youtube.com/watch?v=txddWoSqWHY&list=PLZH3AKTCrVsXEcdJJep6fl4Mb2Wy4eHL5" className={`${topStyles.contentsBannerCard} ${topStyles.interviewVol1}`} target="_blank" rel="noopener noreferrer">
              <img src="/images/top/interview-banner-vol1.png" alt="" />
              <div className={topStyles.contentsBannerCardMoveiButton}>
                <img src="/images/top/movie-button.png" alt="" />
              </div>
              <h2 className={topStyles.contentsBannerCardSmallTitle}>
              経営者インタビュー 〜水素サプライチェーン〜
              </h2>
            </a>
            <a href="https://www.youtube.com/watch?v=zyC8HzQURPw" className={topStyles.contentsBannerCard} target="_blank" rel="noopener noreferrer">
              <img src="/images/top/pic-conceptMovie.png" alt="" />
              <div className={topStyles.contentsBannerCardMoveiButton}>
                <img src="/images/top/movie-button.png" alt="" />
              </div>
              <h2 className={topStyles.contentsBannerCardSmallTitle}>
                グリーンイノベーション基金事業コンセプト動画
              </h2>
            </a>
          </div>
        </div>
      </main>

    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`
